import React from 'react';
import { Container, Box, Typography, Grid, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useTheme } from '@mui/material/styles';

import { Navbar, Footer, LumpsumCalculator, Mobile } from '../../components';
import { lumpsumCalculator } from '../../data';
import { lumpsumCalculatorImage } from '../../assets';

const LumpsumCalculatorPage = () => {

  const theme = useTheme();

  return (
    <>
    <Box sx={{ height: '100%', backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', display: 'flex', flexDirection: 'column', paddingBottom: 6 }}>
      <Navbar />
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LumpsumCalculator />
      </Box>
    </Box>
    <Container sx={{ marginTop: { xs: '2rem', md: '4rem', lg: '3rem' } }}>
      <Typography variant='h4'>
        <span style={theme.typography.gradientText}>Lumpsum Calculator</span>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'justify' }}>
          <Typography variant='p'>{lumpsumCalculator.about}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component='img'
          src={lumpsumCalculatorImage}
          alt='tax Image'
          height='270px'
        />
        </Grid>
      </Grid>

      <Typography variant='h4' sx={{ marginTop: '4rem' }}>
        How to use Fi-Enhance's <span style={theme.typography.gradientText}>Lumpsum Calculator</span>
      </Typography>
      <Typography variant='p'>
      <List>
        {lumpsumCalculator.howTo.map((point) => (
          <ListItem>
            <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
              <DoubleArrowIcon />
            </ListItemAvatar>
            <ListItemText primary={point} />
          </ListItem>
        ))}
      </List>
      </Typography>

    </Container>

    <Mobile />

    <Container>
      <Typography variant='h4'>
        Benefits of using <span style={theme.typography.gradientText}>Lumpsum Calculator</span>
      </Typography>
      <Typography variant='p'>
      <List>
        {lumpsumCalculator.benefits.map((point) => (
          <ListItem>
            <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
              <DoubleArrowIcon />
            </ListItemAvatar>
            <ListItemText primary={point} />
          </ListItem>
        ))}
      </List>
      </Typography>
    </Container>
    
    <Footer />
    </>
  )
}

export default LumpsumCalculatorPage