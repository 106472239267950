import React from 'react';
import { Navbar, Footer, Mobile, CalculatorCard } from '../components'
import { Container, Box, Typography, Grid, useMediaQuery, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { fiEnhanceContent } from '../data';
import { heroImage, sipTaxCalculatorImage, sipReturnsCalculatorImage, fdReturnsCalculatorImage, lumpsumCalculatorImage, girlImage } from '../assets';

const Home = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
    <Box sx={{ height: '100vh', backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', display:'flex', flexDirection: 'column' }} >
      <Navbar />
      <Container sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={heroImage} alt="HeroImg" width={ isMobile ? '80%' : '100%'} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem', paddingLeft: { xs: '0', md: '4rem' } }}>
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ textAlign: { xs: 'center', md: 'left' } }} >
              Plan. Calculate. Save.
            </Typography>
            <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ fontWeight: 'bold', color: '#fff', textAlign: { xs: 'center', md: 'left' } }}>
              Your financial toolkit at your fingertips
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>

    <Container sx={{ my: { xs: '5rem', md: '6rem', lg: '7rem' } }}>
      <Typography variant='h4'>
        Our <span style={theme.typography.gradientText}>Calculators</span>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7} >
          <Grid container spacing={{ xs: 3, sm: 3, md: 6, lg: 6 }}>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center',  }}>
              <Link to='/sip-tax-calculator' onClick={handleLinkClick}>
                <CalculatorCard  image={sipTaxCalculatorImage} name='SIP Tax Calculator' comingSoon={false} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center', }}>
              <Link to='/sip-returns-calculator' onClick={handleLinkClick}>
                <CalculatorCard  image={sipReturnsCalculatorImage} name='SIP Returns Calculator' comingSoon={false} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center',  }}>
              <Link to='/fd-returns-calculator' onClick={handleLinkClick}>
                <CalculatorCard  image={fdReturnsCalculatorImage} name='FD Returns Calculator' comingSoon={false} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center',  }}>
              <Link to='/lumpsum-calculator' onClick={handleLinkClick}>
                <CalculatorCard  image={lumpsumCalculatorImage} name='Lumpsum Calculator' comingSoon={false} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }} >
          <img src={girlImage} alt="GirldImg" width={ isMobile ? '80%' : '75%'} />
        </Grid>
      </Grid>
      
    </Container>

    <Mobile id="mobile"/>

    <Container>
     <Typography variant='h4'>
        About <span style={theme.typography.gradientText}>Fi-Enhance</span>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'justify' }}>
        <Typography variant='p'>
        {fiEnhanceContent.headerNote}
        <List>
          {fiEnhanceContent.points.map((point) => (
            <ListItem>
              <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
                <DoubleArrowIcon />
              </ListItemAvatar>
              <ListItemText primary={point} />
            </ListItem>
          ))}
        </List>
        {fiEnhanceContent.footerNote}
        </Typography>
      </Box>
      
    </Container>
    
    <Footer />
    </>
  )
}

export default Home